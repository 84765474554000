<template>
  <div class="info-page d-flex flex-column justify-content-center">
    <div class="info-page__about">
      <p>
        Whichcocktailtonight is a free web application designed by cocktail
        lovers to help you make cocktails given what you have in your bar.
      </p>
      <p>
        Don't hesitate to
        <a
          class="info-page__about-mail"
          href="mailto:contact@whichcocktailtonight.com"
        >
          contact us
        </a>
        to give us some feedback, report a bug, suggest new features or new
        recipes.<br />Many thanks for your help!
      </p>
    </div>
    <div class="info-page__legal d-flex">
      <div class="info-page__legal-section">
        <div class="info-page__legal-title">Site publisher</div>
        <div class="info-page__legal-content">
          Cécile BARNOUD<br />40 Rue de Trévise<br />75009 PARIS
        </div>
      </div>
      <div class="info-page__legal-section">
        <div class="info-page__legal-title">Hosting</div>
        <div class="info-page__legal-content">
          Amazon Web Services, Inc.<br />P.O. Box 81226<br />Seattle, WA
          98108-1226
        </div>
      </div>
    </div>
    <footer class="info-page__footer d-flex justify-content-center">
      <div class="info-page__footer-links">&copy;2020 Whichcocktailtonight</div>
      <div class="d-flex justify-content-center">
        <div class="info-page__footer-links-separator hide">-</div>
        <a
          class="info-page__footer-links"
          href="mailto:contact@whichcocktailtonight.com"
        >
          Contact
        </a>
        <div class="info-page__footer-links-separator d-flex">-</div>
        <router-link to="/credits" class="info-page__footer-links">
          Credits
        </router-link>
        <div class="info-page__footer-links-separator d-flex">-</div>
        <a
          class="info-page__footer-links"
          href="https://www.facebook.com/Which-Cocktail-Tonight-104802304615612"
          target="_blank"
        >
          <img
            alt="coloredfacebook icon"
            src="../assets/svg/coloredfacebook.svg"
            v-svg-inline
          />
        </a>
      </div>
    </footer>
    <div class="recipe-page__stock">
      <StockBox />
    </div>
  </div>
</template>

<script>
import StockBox from "@/components/StockBox.vue";

export default {
  name: "info-page",
  components: {
    StockBox,
  },
};
</script>
